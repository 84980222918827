import React from 'react'
import styled from 'styled-components'
// todo: navbar
// todo: what are the nav items
// todo: content
// todo: find inspo
// todo: footer

const ContentCard = styled.main`
    border: 3px solid black;
    background: #fcfce8;
    height: 100vh;
`

const NavItems = styled.nav`
    display: flex;
    justify-content: space-around;
    list-style-type: none;
`

function App() {
    return (
        <ContentCard>
            <nav>
                <NavItems>
                    <li>Home</li>
                    <li>About</li>
                    <li>Recipes</li>
                    <li>News</li>
                    <li>Contact us</li>
                </NavItems>
            </nav>
        </ContentCard>
    )
}

export default App
